<div class="animated fadeIn">
  <!--#region Header-->
  <h1 *ngIf="section == null"></h1>
  <div *ngIf="section != null">
    <h3>
      <img src="https://img.icons8.com/color/200/000000/list.png" class="rounded me-2"
        style="object-fit: contain; width: 30px; height: 30px;">
      <span class="align-middle">{{section.title}}</span>
    </h3>
    <!-- <div class="card p-2">
      <small *ngIf="resource != null">
        <img src="https://img.icons8.com/color/200/000000/down-right.png" class="rounded mr-2"
        style="object-fit: contain; width: 20px; height: 20px;">
        <img src="https://img.icons8.com/color/200/000000/open-book--v1.png" class="rounded mr-2"
        style="object-fit: contain; width: 20px; height: 20px;">
        <a class="align-middle" routerLink="/resources/{{resource.id}}">{{resource.title}}</a>
      </small>
    </div> -->

  </div>
  <!--#endregion-->

  <!--#region Loading: Content-->
  <div *ngIf="viewState == viewStates.loading" class="text-center">
    <div class="spinner-border text-ts" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <!--#endregion-->

  <!--#region Content-->
  <div *ngIf="section != null && viewState == viewStates.content && compareViewState == viewStates.initial"
    class="mt-4">
    <div class="row">
      <div class="col-9">
        <select class="form-control w-100" [(ngModel)]="selectedRevisionId"
          (ngModelChange)="revisionDidChange(selectedRevisionId)">
          <option *ngFor="let revision of revisions; let i = index" [value]="revision.id">{{revisions.length-i}} -
            {{moment(revision.timestampCreated).format('ll')}}</option>
          <option [value]="0">Default Revision</option>
        </select>
      </div>
      <div class="col-1">
        <button class="btn btn-pill btn-dark btn-ts w-100"
          (click)="compareViewState = viewStates.content">Compare</button>
      </div>
      <div class="col-1">
        <button class="btn btn-pill btn-dark btn-ts w-100" (click)="confirmAddRevision()">Add</button>
      </div>
      <div class="col-1">
        <button [disabled]="selectedRevisionId == null" class="btn btn-pill btn-dark btn-ts w-100"
          (click)="confirmDeleteRevision(selectedRevisionId)">Delete</button>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-3">
        <!--#region Info Card-->
        <div class="card">
          <div class="card-header">
            <b>Section Info</b>
          </div>
          <div class="card-body">
            <!--loading-->
            <div *ngIf="infoViewState == viewStates.loading" class="text-center">
              <div class="spinner-border text-ts" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>

            <div *ngIf="infoViewState == viewStates.content">
              <!--info-->
              <div class="form-group">
                <label for="name">Title</label>
                <input type="text" id="section-title" placeholder="Section title" class="form-control"
                  value={{section.title}} [(ngModel)]="section.title">
              </div>

              <!--Reference-->
              <div class="form-group">
                <label for="name">Reference Type & ID</label>
                <button class="btn btn-block btn-outline btn-outline-ts w-100 mb-2 mt-2"
                  (click)="presentReferencyType()">
                  <img class="me-2" src="https://img.icons8.com/color/20/000000/book-shelf.png" />
                  <span *ngIf="section.referenceType == null">Section</span>
                  <span *ngIf="section.referenceType != null">{{section.referenceType.name}}</span>
                </button>
                <input type="text" id="section-referenceId" placeholder="Section Reference ID" class="form-control"
                  value={{section.referenceId}} [(ngModel)]="section.referenceId">
              </div>

              <div class="form-group">
                <label for="name">Range</label>
                <input type="text" id="section-title" placeholder="Section title" class="form-control"
                  value={{section.sectionRange}} [(ngModel)]="section.sectionRange">
              </div>

              <!--checkboxes-->
              <div class="row text-center">
                <div class="col-md-6">
                  <label class="switch switch-label switch-ts">
                    <input type="checkbox" class="switch-input" [checked]="section.published"
                      (change)="section.published = !section.published">
                    <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
                  </label>
                  <div>Public</div>
                </div>
                <div class="col-md-6">
                  <label class="switch switch-label switch-ts">
                    <input type="checkbox" class="switch-input" [checked]="section.free"
                      (change)="section.free = !section.free">
                    <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
                  </label>
                  <div>Free Preview</div>
                </div>
              </div>

              <div class="text-center">
                <button class="btn btn-pill btn-dark btn-ts w-100 mb-2 mt-2" (click)="saveSection()">
                  <i class="fa fa-save me-2"></i>
                  <span>Save</span>
                </button>

                <!--updated-->
                <div *ngIf="section.updatedTimestamp != null" class="small mt-2 ms-2">
                  <span>Updated </span>
                  <span>{{moment(section.updatedTimestamp).format('ll')}}</span>
                </div>

                <hr />

                <div class="text-danger"><b>Danger Zone</b></div>
                <div><small>These are destructive operations. Proceed with care.</small></div>
                <button class="btn btn-pill btn-danger w-100 mb-2 mt-2" (click)="confirmDelete()">
                  <i class="fa fa-trash me-2"></i>
                  <span>Delete</span>
                </button>

              </div>
            </div>
          </div>
        </div>
        <!--#endregion-->
      </div>
      <div class="col-md-9">
        <!--#region Tabs-->
        <tabset>
          <tab>
            <ng-template tabHeading><i class="fa fa-list"></i> Sections</ng-template>
            <c-card *ngIf="viewState == viewStates.content" class="mb-4">
              <c-card-body>
                <c-row>
                  <c-col sm="7">
                    <h3 class="card-title mb-0" id="traffic">
                      <img src="https://img.icons8.com/color/200/000000/books.png" class="rounded me-2"
                        style="object-fit: contain; width: 30px; height: 30px;">
                      <b>Sections</b>
                    </h3>
                  </c-col>
                  <c-col sm="5">
                    <div style="text-align: right;">
                      <button class="btn btn-pill btn-dark btn-ts" data-toggle="modal" (click)="presentAddSection()">
                        <i class="fa fa-plus me-2"></i> Add Section
                      </button>
                    </div>
                  </c-col>
                </c-row>

                <div *ngIf="sections != null" class="mt-4">
                  <div class="card mb-2" *ngFor="let section of sections; let i = index">
                    <app-section-list-item [resource]='resource' [section]='section'></app-section-list-item>
                  </div>
                  <div *ngIf="sections.length == 0" class="text-center"><img
                      src="https://img.icons8.com/cute-clipart/100/000000/list.png">
                    <h2>No Sections Found</h2>
                    <p>This section does not appear to have any subsections. To create a subsection, press the "+ Add"
                      button below.</p>
                    <button type="button" class="btn-pill btn btn-dark btn-ts" (click)="presentAddSection()">
                      <b><i class="fa fa-plus me-2"></i>Add Section</b>
                    </button>
                  </div>
                </div>
              </c-card-body>
            </c-card>
          </tab>
          <tab>
            <ng-template tabHeading><i class="fa fa-paragraph"></i> Content</ng-template>
            <c-card *ngIf="viewState == viewStates.content" class="mb-4">
              <c-card-body>
                <c-row class="mb-4">
                  <c-col sm="7">
                    <h3 class="card-title mb-0" id="traffic">
                      <b>Content</b>
                    </h3>
                  </c-col>
                  <c-col sm="5">
                    <div style="text-align: right;">
                      <button class="btn btn-pill btn-dark btn-ts me-2" data-toggle="modal" (click)="presentFiles()">
                        <i class="fa fa-folder me-2"></i> Files
                      </button>
                      <button *ngIf="quickSaveViewState == viewStates.content" class="btn btn-pill btn-dark btn-ts"
                        data-toggle="modal" (click)="quickSave()">
                        <i class="fa fa-save me-2"></i> Quick save
                      </button>
                      <span *ngIf="quickSaveViewState == viewStates.loading">Saving...</span>
                      <span *ngIf="quickSaveViewState == viewStates.updated"><i class="fa fa-check-circle me-2"></i>
                        Saved</span>
                    </div>
                  </c-col>
                </c-row>

                <ckeditor *ngIf="selectedRevision" [editor]="ContentEditor" (ready)="onReady($event)"
                  [(ngModel)]="selectedRevision.content"></ckeditor>
                <ckeditor *ngIf="!selectedRevision" [editor]="ContentEditor" (ready)="onReady($event)"
                  [(ngModel)]="section.content"></ckeditor>
              </c-card-body>
            </c-card>
          </tab>
          <tab>
            <ng-template tabHeading><i class="fa fa-list"></i> Summary</ng-template>
            <c-card *ngIf="viewState == viewStates.content" class="mb-4">
              <c-card-body>
                <c-row class="mb-4">
                  <c-col sm="7">
                    <h3 class="card-title mb-0" id="traffic">
                      <b>Summary</b>
                    </h3>
                  </c-col>
                  <c-col sm="5">
                    <div style="text-align: right;">
                      <button class="btn btn-pill btn-dark btn-ts me-2" data-toggle="modal" (click)="presentFiles()">
                        <i class="fa fa-folder me-2"></i> Files
                      </button>
                      <button *ngIf="quickSaveViewState == viewStates.content" class="btn btn-pill btn-dark btn-ts"
                        data-toggle="modal" (click)="quickSave()">
                        <i class="fa fa-save me-2"></i> Quick save
                      </button>
                      <span *ngIf="quickSaveViewState == viewStates.loading">Saving...</span>
                      <span *ngIf="quickSaveViewState == viewStates.updated"><i class="fa fa-check-circle me-2"></i>
                        Saved</span>
                    </div>
                  </c-col>
                </c-row>

                <ckeditor *ngIf="selectedRevision" [editor]="SummaryEditor" (ready)="onReady($event)"
                  [(ngModel)]="selectedRevision.summary"></ckeditor>
                <ckeditor *ngIf="!selectedRevision" [editor]="SummaryEditor" (ready)="onReady($event)"
                  [(ngModel)]="section.summary"></ckeditor>
              </c-card-body>
            </c-card>
          </tab>
          <tab>
            <ng-template tabHeading><i class="fa fa-commenting"></i> Commentary</ng-template>
            <c-card *ngIf="viewState == viewStates.content" class="mb-4">
              <c-card-body>
                <c-row class="mb-4">
                  <c-col sm="7">
                    <h3 class="card-title mb-0" id="traffic">
                      <b>Commentary</b>
                    </h3>
                  </c-col>
                  <c-col sm="5">
                    <div style="text-align: right;">
                      <button class="btn btn-pill btn-dark btn-ts me-2" data-toggle="modal" (click)="presentFiles()">
                        <i class="fa fa-folder me-2"></i> Files
                      </button>
                      <button *ngIf="quickSaveViewState == viewStates.content" class="btn btn-pill btn-dark btn-ts"
                        data-toggle="modal" (click)="quickSave()">
                        <i class="fa fa-save me-2"></i> Quick save
                      </button>
                      <span *ngIf="quickSaveViewState == viewStates.loading">Saving...</span>
                      <span *ngIf="quickSaveViewState == viewStates.updated"><i class="fa fa-check-circle me-2"></i>
                        Saved</span>
                    </div>
                  </c-col>
                </c-row>


                <ckeditor *ngIf="selectedRevision" [editor]="CommentaryEditor" (ready)="onReady($event)"
                  [(ngModel)]="selectedRevision.commentary"></ckeditor>
                <ckeditor *ngIf="!selectedRevision" [editor]="CommentaryEditor" (ready)="onReady($event)"
                  [(ngModel)]="section.commentary"></ckeditor>
              </c-card-body>
            </c-card>
          </tab>
          <tab>
            <ng-template tabHeading><i class="fa fa-graduation-cap"></i> Interpretation</ng-template>
            <c-card *ngIf="viewState == viewStates.content" class="mb-4">
              <c-card-body>
                <c-row class="mb-4">
                  <c-col sm="7">
                    <h3 class="card-title mb-0" id="traffic">
                      <b>Interpretation</b>
                    </h3>
                  </c-col>
                  <c-col sm="5">
                    <div style="text-align: right;">
                      <button class="btn btn-pill btn-dark btn-ts me-2" data-toggle="modal" (click)="presentFiles()">
                        <i class="fa fa-folder me-2"></i> Files
                      </button>
                      <button *ngIf="quickSaveViewState == viewStates.content" class="btn btn-pill btn-dark btn-ts"
                        data-toggle="modal" (click)="quickSave()">
                        <i class="fa fa-save me-2"></i> Quick save
                      </button>
                      <span *ngIf="quickSaveViewState == viewStates.loading">Saving...</span>
                      <span *ngIf="quickSaveViewState == viewStates.updated"><i class="fa fa-check-circle me-2"></i>
                        Saved</span>
                    </div>
                  </c-col>
                </c-row>

                <ckeditor *ngIf="selectedRevision" [editor]="InterpretationEditor" (ready)="onReady($event)"
                  [(ngModel)]="selectedRevision.interpretation"></ckeditor>
                <ckeditor *ngIf="!selectedRevision" [editor]="InterpretationEditor" (ready)="onReady($event)"
                  [(ngModel)]="section.interpretation"></ckeditor>
              </c-card-body>
            </c-card>
          </tab>
          <tab>
            <ng-template tabHeading><i class="fa fa-building"></i> OSHA</ng-template>
            <c-card *ngIf="viewState == viewStates.content" class="mb-4">
              <c-card-body>
                <c-row class="mb-4">
                  <c-col sm="7">
                    <h3 class="card-title mb-0" id="traffic">
                      <b>OSHA</b>
                    </h3>
                  </c-col>
                  <c-col sm="5">
                    <div style="text-align: right;">
                      <button class="btn btn-pill btn-dark btn-ts me-2" data-toggle="modal" (click)="presentFiles()">
                        <i class="fa fa-folder me-2"></i> Files
                      </button>
                      <button *ngIf="quickSaveViewState == viewStates.content" class="btn btn-pill btn-dark btn-ts"
                        data-toggle="modal" (click)="quickSave()">
                        <i class="fa fa-save me-2"></i> Quick save
                      </button>
                      <span *ngIf="quickSaveViewState == viewStates.loading">Saving...</span>
                      <span *ngIf="quickSaveViewState == viewStates.updated"><i class="fa fa-check-circle me-2"></i>
                        Saved</span>
                    </div>
                  </c-col>
                </c-row>

                <ckeditor *ngIf="selectedRevision" [editor]="OshaEditor" (ready)="onReady($event)"
                  [(ngModel)]="selectedRevision.osha"></ckeditor>
                <ckeditor *ngIf="!selectedRevision" [editor]="OshaEditor" (ready)="onReady($event)"
                  [(ngModel)]="section.osha"></ckeditor>
              </c-card-body>
            </c-card>
          </tab>
        </tabset>
        <!--#endregion-->
      </div>
    </div>

  </div>
  <!--#endregion-->

  <!--#region compare-->
  <c-card *ngIf="compareViewState == viewStates.content" class="p-4">
    <c-row>
      <c-col sm="7">
        <h3 class="card-title mb-0" id="traffic">
          <img src="https://img.icons8.com/stickers/100/diff-files.png" class="rounded me-2"
            style="object-fit: contain; width: 30px; height: 30px;">
          <b>Compare Revisions</b>
        </h3>
      </c-col>
      <c-col sm="5">
        <div style="text-align: right;">
          <c-form-check inline>
            <input cFormCheckInput id="compareTypeSideBySide" name="radioinline" type="radio"
              (click)="setCompareType(revisionCompareTypes.sideBySide)" />
            <label cFormCheckLabel for="compareTypeSideBySide">Side-By-Side</label>
          </c-form-check>
          <c-form-check inline>
            <input cFormCheckInput checked id="compareTypeUnified" name="radioinline" type="radio"
              (click)="setCompareType(revisionCompareTypes.unified)" />
            <label cFormCheckLabel for="compareTypeUnified">Unified</label>
          </c-form-check>

          <button class="btn btn-pill btn-dark btn-ts" (click)="compareViewState = viewStates.initial">
            Stop Comparing
          </button>
        </div>
      </c-col>
    </c-row>

    <!--content type selection-->
    <c-row class="mt-4">
      <c-col xs="12">
        <select class="form-control w-100" [(ngModel)]="revisionContentType"
          (ngModelChange)="revisionContentTypeDidChange()">
          <option [value]="revisionContentTypes.content">Content</option>
          <option [value]="revisionContentTypes.summary">Summary</option>
          <option [value]="revisionContentTypes.commentary">Commentary</option>
          <option [value]="revisionContentTypes.interpretation">Interpretation</option>
          <option [value]="revisionContentTypes.osha">OSHA</option>
        </select>
      </c-col>
    </c-row>

    <!--revision selection-->
    <c-row class="mt-4">
      <!--select left-->
      <c-col xs="12" md="6">
        <b>Initial Revision</b>
        <select class="form-control w-100 mt-2" [(ngModel)]="compareLeftRevisionId"
          (ngModelChange)="compareRevisionLeftDidChange(compareLeftRevisionId)">
          <option *ngFor="let revision of revisions; let i = index" [value]="revision.id">{{revisions.length-i}} -
            {{moment(revision.timestampCreated).format('ll')}}</option>
          <option [value]="0">Default Revision</option>
        </select>
      </c-col>

      <!--select right-->
      <c-col xs="12" md="6">
        <b>Compare Revision</b>
        <select class="form-control w-100 mt-2" [(ngModel)]="compareRightRevisionId"
          (ngModelChange)="compareRevisionRightDidChange(compareRightRevisionId)">
          <option *ngFor="let revision of revisions; let i = index" [value]="revision.id">{{revisions.length-i}} -
            {{moment(revision.timestampCreated).format('ll')}}</option>
          <option [value]="0">Default Revision</option>
        </select>
      </c-col>
    </c-row>

    <!--diff-->
    <c-row class="mt-4">
      <c-col *ngIf="revisionCompareType == revisionCompareTypes.unified" xs="12">
        <ngx-unified-diff class="ngx-diff-light-theme" [before]="compareLeftSide()" [after]="compareRightSide()"
          [lineContextSize]="4" />
      </c-col>
      <c-col *ngIf="revisionCompareType == revisionCompareTypes.sideBySide" xs="12">
        <ngx-side-by-side-diff class="ngx-diff-light-theme" [before]="compareLeftSide()" [after]="compareRightSide()"
          [lineContextSize]="4" />
      </c-col>
    </c-row>
  </c-card>
  <!--#endregion-->
</div>

<!--#region add section modal-->
<div bsModal #addSectionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="addSectionModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Add Section</h4>
        <button type="button" class="close" (click)="addSectionModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!--loading-->
        <div *ngIf="addSectionViewState == viewStates.loading" class="text-center">
          <div class="spinner-border text-ts" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <!--info-->
        <div *ngIf="addSectionViewState == viewStates.content">
          <div class="form-group">
            <label for="name">Title</label>
            <input type="text" id="section-title" placeholder="Section title" class="form-control"
              value={{newSection.title}} [(ngModel)]="newSection.title">
          </div>

          <!--Reference-->
          <div class="form-group">
            <label for="name">Reference Type & ID</label>
            <input type="text" id="section-referenceId" placeholder="Section Reference ID" class="form-control"
              value={{newSection.referenceId}} [(ngModel)]="newSection.referenceId">
          </div>

          <div class="form-group">
            <label for="name">Range</label>
            <input type="text" id="section-title" placeholder="Section title" class="form-control"
              value={{newSection.sectionRange}} [(ngModel)]="newSection.sectionRange">
          </div>

          <!--checkboxes-->
          <div class="row text-center">
            <div class="col-md-6">
              <label class="switch switch-label switch-ts">
                <input type="checkbox" class="switch-input" [checked]="newSection.published"
                  (change)="newSection.published = !newSection.published">
                <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
              </label>
              <div>Public</div>
            </div>
            <div class="col-md-6">
              <label class="switch switch-label switch-ts">
                <input type="checkbox" class="switch-input" [checked]="newSection.free"
                  (change)="newSection.free = !newSection.free">
                <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
              </label>
              <div>Free Preview</div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="addSectionModal.hide()">Close</button>
        <button type="button" class="btn btn-dark btn-ts" (click)="addSection(newSection)">Save changes</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
<!--#endregion /.modal -->

<!--#region reference type modal-->
<div bsModal #referenceTypeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="referenceTypeModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          <img src="https://img.icons8.com/color/50/000000/book-shelf.png" />
          <span>Select Reference Type</span>
        </h4>
        <button type="button" class="close" (click)="referenceTypeModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!--loading-->
        <div *ngIf="referenceTypeViewState == viewStates.loading" class="text-center">
          <div class="spinner-border text-ts" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <!--info-->
        <div *ngIf="referenceTypeViewState == viewStates.content">
          <!--#region No Content-->
          <div *ngIf="sectionTypes.length == 0" className="text-center">
            <img src="https://img.icons8.com/color/50/000000/book-shelf.png" />
            <h6 className="mt-2"><b>No Reference Types Found</b></h6>
            <p>Create a reference type by using the text field above.</p>
          </div>
          <!--#endregion No content-->
          <!--#region Content-->
          <div *ngIf="sectionTypes.length > 0">
            <!--add new-->
            <div class="row">
              <div class="col-md-10">
                <div class="form-group">
                  <input class="form-control" placeholder="Add Reference Type" name="title"
                    value={{newSectionType.name}} [(ngModel)]="newSectionType.name" />
                </div>
              </div>
              <div class="col-md-2">
                <button class="btn btn-pill btn-dark btn-ts w-100" (click)='addReferenceType(newSectionType)'><b>+
                    Add</b></button>
              </div>
            </div>

            <!--list-->
            <table class="table table-responsive" style="max-height: 400px;">
              <tr *ngFor="let type of sectionTypes; let i = index" class="card d-block mb-2" style="cursor: pointer;"
                (click)="didSelectReferenceType(type)">
                <td class="border-top-0">
                  <img src="https://img.icons8.com/color/20/000000/book-shelf.png" />
                </td>
                <td class="border-top-0">
                  <b>{{type.name}}</b>
                </td>
              </tr>
            </table>
          </div>
          <!--#endregion Content-->
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="referenceTypeModal.hide()">Close</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- #endregion /.modal -->

<!--#region reference type modal-->
<div bsModal #filesModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="filesModal"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          <img src="https://img.icons8.com/color/50/000000/folder-invoices--v1.png" />
          <span>File Quick View</span>
        </h4>
        <button type="button" class="close" (click)="filesModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-files-list filesRoute="/v1/files" [picking]="true"></app-files-list>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="filesModal.hide()">Close</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- #endregion /.modal -->