<div class="card-body">
  <div class="me-4 d-inline-block text-center text-body" style="vertical-align: top;">
    <div>
      <b>
        <span *ngIf="section?.referenceType == null">Section</span>
        <span *ngIf="section?.referenceType != null">{{section?.referenceType?.name}}</span>
        <span>&nbsp;</span>
        <span *ngIf="section?.referenceId != null">{{section?.referenceId}}</span>
      </b>

    </div>
    <div><span *ngIf="section?.sectionRange != null">{{section?.sectionRange}}</span></div>
  </div>
  <div class="d-inline-block">
    <a routerLink="/resources/{{resource?.id}}/sections/{{section?.id}}"><b>{{section?.title}}</b></a>
    <div class="mt-2">
      <c-badge *ngIf="section?.published" color="primary">PUBLIC</c-badge>
      <c-badge *ngIf="!(section?.published)" color="light">PRIVATE</c-badge>
      <c-badge *ngIf="section?.free" color="success">FREE PREVIEW</c-badge>
    </div>
  </div>
</div>