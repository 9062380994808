import { Component, OnInit, ViewChild } from '@angular/core';
import { AddSectionDto, AddSectionTypeDto, EditSectionDto, ResourceDto, SectionBaseDto, SectionDto, SectionRevisionDto, SectionTypeDto } from '../../../models/dtos';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { ViewState, RevisionCompareTypes, RevisionContentTypes } from '../../../models/app';
import DocumentEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import Swal from 'sweetalert2'
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MyUploadAdapter } from '../../../adapters/my-upload-adapter';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { convert as htmlConvert } from "html-to-text";
import { Location } from '@angular/common';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: []
})
export class SectionComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  public id?: string | null;
  public sectionId?: string | null;
  public resource?: ResourceDto | null;
  public section?: SectionDto | null;
  public sections?: SectionBaseDto[] | null;
  moment: any = moment;
  newSection: AddSectionDto = new AddSectionDto();
  sectionTypes: SectionTypeDto[] = []
  newSectionType: AddSectionTypeDto = new AddSectionTypeDto();
  revisions: SectionRevisionDto[] = [];
  selectedRevisionId: number = 0;
  selectedRevision?: SectionRevisionDto | null;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  infoViewState = ViewState.loading;
  addSectionViewState = ViewState.content;
  quickSaveViewState = ViewState.content;
  referenceTypeViewState = ViewState.content;
  compareViewState = ViewState.initial;

  //editors
  public ContentEditor = DocumentEditor;
  public SummaryEditor = DocumentEditor;
  public CommentaryEditor = DocumentEditor;
  public InterpretationEditor = DocumentEditor;
  public OshaEditor = DocumentEditor;

  //modals
  @ViewChild('addSectionModal') public addSectionModal?: ModalDirective;
  @ViewChild('referenceTypeModal') public referenceTypeModal?: ModalDirective;
  @ViewChild('filesModal') public filesModal?: ModalDirective;

  //comparision states
  revisionCompareTypes = RevisionCompareTypes;
  revisionCompareType = RevisionCompareTypes.unified;
  revisionContentTypes = RevisionContentTypes;
  revisionContentType = RevisionContentTypes.content;
  compareLeftRevisionId: number = 0;
  compareRightRevisionId: number = 0;
  compareLeftRevision?: SectionRevisionDto | null;
  compareRightRevision?: SectionRevisionDto | null;

  ngOnInit() {
    //update title
    this.titleService.setTitle('Section  | Trucking Standards Admin');

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id');
    this.sectionId = this.route.snapshot.paramMap.get('sectionId');
    this.getResource(this.id);
    this.getSection(this.sectionId);
    this.getSections(this.sectionId);
    this.getRevisions(this.sectionId);

    //register for route changes
    this.route.params.subscribe((params) => {
      this.id = this.route.snapshot.paramMap.get('id');
      this.sectionId = this.route.snapshot.paramMap.get('sectionId');

      //get resource and data
      this.section = null;
      this.sections = null;
      this.selectedRevision = null;
      this.selectedRevisionId = 0;
      this.getResource(this.id);
      this.getSection(this.sectionId);
      this.getSections(this.sectionId);
      this.getRevisions(this.sectionId);
    });
  }

  //region API
  getResource(id: string | null) {
    this.http
      .get<ResourceDto>(`${environment.services_legacy_management}/v1/resources/${id}`)
      .subscribe(result => {
        this.resource = result;
      });
  }

  getSection(id: string | null) {
    this.viewState = ViewState.loading;

    this.http
      .get<SectionDto>(`${environment.services_legacy_management}/v1/sections/${this.sectionId}`)
      .subscribe(result => {
        this.section = result;
        this.infoViewState = ViewState.content;
        this.viewState = ViewState.content;
      });
  }

  getSections(id: string | null) {
    this.http
      .get<SectionBaseDto[]>(`${environment.services_legacy_management}/v1/sections/${this.sectionId}/sections`)
      .subscribe(result => {
        this.sections = result;
      });
  }

  deleteSection() {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_legacy_management}/v1/sections/${this.sectionId}`)
      .subscribe(() => {
        //redirect to resource, eventually get breadcrumbs and navigate to parent
        this._router.navigateByUrl(`/resources/${this.id}`)
      }, (error) => {
        this.viewState = ViewState.content;
      });
  }

  addSection(newSection: AddSectionDto) {
    newSection.resourceId = Number(this.id);
    newSection.parentId = Number(this.sectionId);
    this.addSectionViewState = ViewState.loading;

    this.http
      .post<SectionBaseDto>(`${environment.services_legacy_management}/v1/sections`, newSection)
      .subscribe(section => {
        this.sections?.push(section);
        this.addSectionModal?.hide();
        this.addSectionViewState = ViewState.content;
      }, (error) => {

      });
  }

  getSectionTypes() {
    this.referenceTypeViewState = this.viewStates.loading

    this.http
      .get<SectionTypeDto[]>(`${environment.services_legacy_management}/v1/sectionTypes`)
      .subscribe(result => {
        this.sectionTypes = result;
        this.referenceTypeViewState = this.viewStates.content
      });
  }

  addReferenceType(newSectionType: AddSectionTypeDto) {
    this.referenceTypeViewState = this.viewStates.loading

    this.http
      .post<SectionTypeDto>(`${environment.services_legacy_management}/v1/sectionTypes`, newSectionType)
      .subscribe(type => {
        this.referenceTypeViewState = this.viewStates.content;
        this.sectionTypes = [type].concat(this.sectionTypes);
      }, (error) => {
        this.referenceTypeViewState = this.viewStates.content;
      });
  }

  getRevisions(id: string | null) {
    this.http
      .get<SectionRevisionDto[]>(`${environment.services_legacy_management}/v1/sections/${this.sectionId}/revisions`)
      .subscribe(result => {
        this.revisions = result;
        if (this.revisions.length > 0) {
          this.selectedRevision = this.revisions[0];
          this.selectedRevisionId = this.revisions[0].id;

          this.compareLeftRevision = this.revisions[0]; //last
          this.compareLeftRevisionId = this.compareLeftRevision.id;
        }
        if (this.revisions.length > 1) {
          this.compareRightRevision = this.revisions[1]; //next to last
          this.compareRightRevisionId = this.compareRightRevision.id;
        }
      });
  }

  addRevision() {
    this.http
      .post<SectionRevisionDto>(`${environment.services_legacy_management}/v1/sections/${this.sectionId}/revisions`, null)
      .subscribe((result) => {
        this.revisions = [result].concat(this.revisions);
        this.selectedRevisionId = result.id;
        this.selectedRevision = result;
      });
  }

  deleteRevision(revisionId: number) {
    this.http
      .delete(`${environment.services_legacy_management}/v1/sections/${this.sectionId}/revisions/${revisionId}`)
      .subscribe(() => {
        window.location.reload();
      });
  }
  //endregion

  saveSection() {
    this.infoViewState = ViewState.loading;
    const editDto = this.editDtoFromSection(this.section);

    this.http
      .put<SectionDto>(`${environment.services_legacy_management}/v1/sections/${this.sectionId}`, editDto)
      .subscribe(section => {
        this.section = section;
        this.infoViewState = ViewState.content;
      });
  }

  quickSave() {
    if (this.selectedRevision != null) {
      this.quickSaveRevision(this.selectedRevision);
    }
    else {
      this.quickSaveSection();
    }
  }

  quickSaveSection() {
    const editDto = this.editDtoFromSection(this.section);
    this.quickSaveViewState = ViewState.loading;

    this.http
      .put<SectionDto>(`${environment.services_legacy_management}/v1/sections/${this.sectionId}`, editDto)
      .subscribe(section => {
        this.section = section;

        //update save state
        this.quickSaveViewState = ViewState.updated;
        setTimeout(() => {
          this.quickSaveViewState = ViewState.content;
        }, 1000);
      });
  }

  quickSaveRevision(revision: SectionRevisionDto) {
    this.quickSaveViewState = ViewState.loading;

    this.http
      .put<SectionRevisionDto>(`${environment.services_legacy_management}/v1/sections/${this.sectionId}/revisions/${revision.id}`, revision)
      .subscribe(revision => {
        this.selectedRevision = revision;

        //update save state
        this.quickSaveViewState = ViewState.updated;
        setTimeout(() => {
          this.quickSaveViewState = ViewState.content;
        }, 1000);
      });
  }

  //transforms
  editDtoFromSection(section?: SectionDto | null): EditSectionDto {
    const dto = new EditSectionDto();
    dto.referenceId = section?.referenceId;
    dto.free = section?.free ?? false;
    dto.published = section?.published ?? false;
    dto.title = section?.title;
    dto.sectionRange = section?.sectionRange;
    dto.referenceType = section?.referenceType;

    dto.content = section?.content;
    dto.summary = section?.summary;
    dto.commentary = section?.commentary;
    dto.interpretation = section?.interpretation;
    dto.osha = section?.osha;

    return dto;
  }

  //modal
  presentAddSection() {
    this.newSection = new AddSectionDto();
    this.addSectionModal?.show();
  }

  //modal
  presentReferencyType() {
    this.newSectionType = new AddSectionTypeDto();
    this.referenceTypeModal?.show();
    this.getSectionTypes();
  }

  presentFiles() {
    this.filesModal?.show();
  }

  //actions
  confirmDelete() {

    Swal.fire({
      title: "Confirm Delete Section",
      text: "Are you sure you want to delete this section and its children?",
      icon: 'warning',
      confirmButtonText: 'Delete',
      confirmButtonColor: "#ff5370",
      showCancelButton: true
    }).then((state) => {
      /* Read more about isConfirmed, isDenied below */
      if (state.value) {
        this.deleteSection();
      }
    });
  }

  didSelectReferenceType(sectionType: SectionTypeDto) {
    this.referenceTypeModal?.hide();
    this.section!.referenceType = sectionType;
    this.saveSection();
  }

  public onReady(editor: any) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    editor.ui.getEditableElement().style.height = '400px';
    editor.ui.getEditableElement().style.maxHeight = '400px';

    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return new MyUploadAdapter(loader, this.http);
    };
  }

  //revisions
  confirmAddRevision() {
    Swal.fire({
      title: "Confirm Add Revision",
      text: "Adding a revision will make a copy of the current content, commentary, etc. and bundle them into a new revision for editing.",
      icon: 'info',
      confirmButtonText: 'Add',
      confirmButtonColor: "#ff5370",
      showCancelButton: true
    }).then((state) => {
      /* Read more about isConfirmed, isDenied below */
      if (state.value) {
        this.addRevision();
      }
    });
  }

  confirmDeleteRevision(revisionId: number) {
    Swal.fire({
      title: "Confirm Delete Revision",
      text: "Are you sure you want to delete this revision?",
      icon: 'warning',
      confirmButtonText: 'Delete',
      confirmButtonColor: "#ff5370",
      showCancelButton: true
    }).then((state) => {
      /* Read more about isConfirmed, isDenied below */
      if (state.value) {
        this.deleteRevision(revisionId);
      }
    });
  }

  revisionDidChange(revisionId: number) {
    this.selectedRevision = this.revisions.find(r => r.id == revisionId);
  }

  //compare
  setCompareType(revisionCompareType: RevisionCompareTypes): void {
    this.revisionCompareType = revisionCompareType;
  }

  revisionContentTypeDidChange() {
    console.log(this.revisionContentType);
  }

  compareRevisionLeftDidChange(revisionId: number) {
    this.compareLeftRevision = this.revisions.find(r => r.id == revisionId);
  }

  compareRevisionRightDidChange(revisionId: number) {
    this.compareRightRevision = this.revisions.find(r => r.id == revisionId);
  }

  compareLeftSide(): string {
    return htmlConvert(this.revisionContentForType(this.compareLeftRevision));
  }

  compareRightSide(): string {
    return htmlConvert(this.revisionContentForType(this.compareRightRevision));
  }

  revisionContentForType(revision?: SectionRevisionDto | null): string {
    const parsedContentType = Number.parseInt(`${this.revisionContentType}`);
    if (parsedContentType == null) { return ''; }

    switch (parsedContentType) {
      case RevisionContentTypes.content:
        return revision?.content ?? this.section?.content ?? '';
      case RevisionContentTypes.summary:
        return revision?.summary ?? this.section?.summary ?? '';
      case RevisionContentTypes.commentary:
        return revision?.commentary ?? this.section?.commentary ?? '';
      case RevisionContentTypes.interpretation:
        return revision?.interpretation ?? this.section?.interpretation ?? '';
      case RevisionContentTypes.osha:
        return revision?.osha ?? this.section?.osha ?? '';
    }

    return '';
  }
}
