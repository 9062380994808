import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { FileResponseDto } from "../models/dtos";

export class MyUploadAdapter {
    loader: any;
    xhr: any;
    http: HttpClient;
    constructor( loader: any, http: HttpClient ) {
        this.loader = loader;
        this.http = http
    }
    upload() {
        return this.loader.file
            .then( (file: any) => new Promise( ( resolve, reject ) => {
                //package form data
                const formData: FormData = new FormData();
                formData.append('file', file, file.name);
            
                //upload
                this.http.post(`${environment.services_legacy_management}/v1/files-upload`, formData)
                .subscribe((result: FileResponseDto) => {
                    resolve({
                        default: result.src
                    });
                  });
            } ) );
    }
    abort() {
        if ( this.xhr ) {
            this.xhr.abort();
        }
    }
}