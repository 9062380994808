import { Component, Input, OnInit } from '@angular/core';
import { ResourceDto, SectionBaseDto } from '../../../models/dtos';

@Component({
  selector: 'app-section-list-item',
  templateUrl: './section-list-item.component.html',
  styleUrls: []
})
export class SectionListItemComponent implements OnInit {

  //vars
  @Input() resource?: ResourceDto | null;
  @Input() section?: SectionBaseDto | null;
  constructor() { }

  ngOnInit() {
  }

}
